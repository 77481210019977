// extracted by mini-css-extract-plugin
export var topContentContainer = "desktop-features-module--topContentContainer--N+ktV";
export var topTitle = "desktop-features-module--topTitle--XnBCc";
export var topDesc = "desktop-features-module--topDesc--9sygV";
export var menuItem = "desktop-features-module--menuItem--kDlTm";
export var menuItemLabel = "desktop-features-module--menuItemLabel--765Ja";
export var menuContainer = "desktop-features-module--menuContainer--WxLqZ";
export var featureTemplate = "desktop-features-module--featureTemplate--JLXts";
export var featureTemplateContentContainer = "desktop-features-module--featureTemplateContentContainer--+Ep9t";
export var featureTemplateDescFigContainer = "desktop-features-module--featureTemplateDescFigContainer--CJTa-";
export var featureTemplateTitleDescContainer = "desktop-features-module--featureTemplateTitleDescContainer--E1Xmn";
export var featureTemplateTitle = "desktop-features-module--featureTemplateTitle--ATFL2";
export var featureTemplateDesc = "desktop-features-module--featureTemplateDesc--MpwUg";
export var featureTemplateStartNow = "desktop-features-module--featureTemplateStartNow--KVLHS";
export var halfContainer = "desktop-features-module--halfContainer--N2Ytc";
export var demoTemplate = "desktop-features-module--demoTemplate--CNs6x";
export var demoUserProfile = "desktop-features-module--demoUserProfile--XaEzM";
export var demoLogo = "desktop-features-module--demoLogo--uXc0c";
export var demoTitle = "desktop-features-module--demoTitle--QG4Sv";
export var demoDesc = "desktop-features-module--demoDesc--9nVrk";
export var bgWhite = "desktop-features-module--bgWhite--IYdfj";
export var bgOrange = "desktop-features-module--bgOrange--xSByV";
export var bgGreen = "desktop-features-module--bgGreen--+sAjn";
export var bgBlue = "desktop-features-module--bgBlue--sm5m6";
export var barGreen = "desktop-features-module--barGreen--WVvEF";
export var barOrange = "desktop-features-module--barOrange--3EAKb";
export var barGray = "desktop-features-module--barGray--kMzxA";
export var barBlue = "desktop-features-module--barBlue--Zwxet";
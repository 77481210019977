// extracted by mini-css-extract-plugin
export var topContainer = "mobile-features-module--topContainer--Xg2xA";
export var topTitle = "mobile-features-module--topTitle--4kqIe";
export var topDesc = "mobile-features-module--topDesc--aWp7w";
export var featureTemplate = "mobile-features-module--featureTemplate--dIgmx";
export var featureTemplateTitle = "mobile-features-module--featureTemplateTitle--1mjcI";
export var featureTemplateDesc = "mobile-features-module--featureTemplateDesc--EfDXl";
export var demoTemplate = "mobile-features-module--demoTemplate---DALk";
export var demoUserProfile = "mobile-features-module--demoUserProfile--u2ZFK";
export var demoTitle = "mobile-features-module--demoTitle--sWPi8";
export var demoDesc = "mobile-features-module--demoDesc--nw606";
export var bgWhite = "mobile-features-module--bgWhite--EaVJO";
export var bgOrange = "mobile-features-module--bgOrange--lyRT6";
export var bgGreen = "mobile-features-module--bgGreen--+7s0E";
export var bgBlue = "mobile-features-module--bgBlue--AVr5j";